// Note: Requires flexslider 2
jQuery(document).ready(function($) {
	//Slider for Slideshow layout
	$('.offers-inner-wrapper.layout-slideshow .flexslider .slides').slick({
		adaptiveHeight: true,
		dots: true,
		slidesToShow: 2,
		responsive: [{
			breakpoint: 769,
			settings: {
				arrows: false,
				slidesToShow: 1
			}
		  }]
	});
	//Slider for Meetings layout
	$('.offers-inner-wrapper.layout-meetings .flexslider .slides').slick({
		dots: true,
		responsive: [{
			breakpoint: 768,
			settings: {
				arrows: false,
			}
		  }]
	});
	// Meetings Slider Accordion
	var trigger = $('.offers-inner-wrapper.layout-meetings .accordion-wrapper');
	trigger.on('click', function(){
		var currentAccordion = $(this);
		currentAccordion.children('.accordion-content').slideToggle(500);
		currentAccordion.children('.accordion-content').toggleClass('content-hidden');
		currentAccordion.find('.toggle-icon').toggle();
	});

}); /* end of as page load scripts */